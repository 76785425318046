import React, { Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";
import { createTheme, ThemeProvider as ThemeProviderUI, adaptV4Theme, StyledEngineProvider } from "@mui/material/styles";

import { lightTheme, darkTheme } from "./Theme";
import useDarkMode from "use-dark-mode";

export const ThemeProviders = (props) => {
  const darkMode = useDarkMode(true);
  const theme = darkMode.value ? darkTheme : lightTheme;

  const THEME = createTheme(adaptV4Theme({
    typography: {
      //   fontFamily: Font,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  }));

  const GlobalStyle = createGlobalStyle` 
  html::-webkit-scrollbar {
    display: none;
  }
  body {
    background-color: ${(props) => props.theme.body.bg};  
    color: ${(props) => props.theme.text.primary};    
    min-height: 100vh;
  }
  .Mui-disabled {
    cursor: no-drop;
  }

  //Date Picker
  .MuiCalendarPicker-root {
    color: ${(props) => props.theme.text.tertiary};
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  .PrivatePickersToolbar-root.css-ctu4nj {
    background-image: url(${process.env.REACT_APP_BASEURL}/public/images/MenuLight.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffff;
  }
  .MuiDialogContent-root {
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  .PrivatePickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer div div .MuiTypography-root.MuiTypography-caption {
    color: ${(props) => props.theme.text.tertiary};
  }
  .MuiCalendarPicker-root div div button.MuiButtonBase-root.MuiIconButton-root {
    color: ${(props) => props.theme.text.tertiary};
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    color: ${(props) => props.theme.text.tertiary};
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
      color: ${(props) => props.theme.text.tertiary};
      background-color: ${(props) => props.theme.drawermenu.bg};
  }
  button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
    color: #ffff;  
    background-color: #70a401;
    border: 1px solid #70a401;  
  }
  button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin:hover {
    background-color: #8db634;
  } 
  button.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    color: ${(props) => props.theme.text.quarternary};
  }
  .PrivatePickersYear-yearButton.Mui-selected {
    color: #ffff;  
    background-color: #70a401;
    border: 1px solid #70a401;  
  }
  .PrivatePickersYear-yearButton.Mui-selected:focus, .PrivatePickersYear-yearButton.Mui-selected:hover {
    background-color: #8db634;
  }
  .MuiDialogActions-root.MuiDialogActions-spacing {
    color: ${(props) => props.theme.text.tertiary};
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root {
    color: ${(props) => props.theme.text.tertiary};
  }

  // .MuiDialogActions-root.MuiDialogActions-spacing.css-1ay6ak0-MuiDialogActions-root {
  //   color: ${(props) => props.theme.text.tertiary};
  //   background-color: ${(props) => props.theme.drawermenu.bg};
  // }
  // button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  //   color: ${(props) => props.theme.text.tertiary};
  // }

  // span.MuiTypography-root.MuiTypography-caption.css-1w13o7u-MuiTypography-root {
  //   color: ${(props) => props.theme.text.tertiary};
  // }
  // button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-7kykdr-MuiButtonBase-root-MuiIconButton-root {
  //   color: ${(props) => props.theme.text.tertiary};
  // }
  // button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall.css-1wgcwo1-MuiButtonBase-root-MuiIconButton-root {
  //   color: ${(props) => props.theme.text.tertiary};
  // }
  // button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall.css-nfrs8p-MuiButtonBase-root-MuiIconButton-root {
  //   color: ${(props) => props.theme.text.tertiary};
  // }
  // .css-epd502 {
  //   color: ${(props) => props.theme.text.tertiary};
  //   background-color: ${(props) => props.theme.drawermenu.bg};
  // }
  // button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  //   color: ${(props) => props.theme.text.tertiary};
  //   background-color: ${(props) => props.theme.drawermenu.bg};
  // }
  // button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  //   color: #ffff;  
  //   background-color: #70a401;
  //   border: 1px solid #70a401;  
  // }
  // button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root:hover {
  //   background-color: #8db634;
  // } 
  // .css-m1gykc.Mui-selected {
  //   color: #ffff;  
  //   background-color: #70a401;
  //   border: 1px solid #70a401;  
  // }
  // .css-m1gykc.Mui-selected:focus, .css-m1gykc.Mui-selected:hover {
  //   background-color: #8db634;
  // }

  // button.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  //   color: ${(props) => props.theme.text.quarternary};
  // }

  // button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-195y93z-MuiButtonBase-root-MuiPickersDay-root {
  //   background-color: ${(props) => props.theme.drawermenu.bg};
  //   border: 1px solid ${(props) => props.theme.text.secondary};
  //   color: ${(props) => props.theme.text.primary};
  // }

  //Data table
  .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
    color: ${(props) => props.theme.text.quarternary};
  }
  .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active:hover {
    color: ${(props) => props.theme.text.tertiary};
  }
`;

  return (
    <Suspense fallback={null}>
      <StyledEngineProvider injectFirst>
        <ThemeProviderUI theme={THEME}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {props.children}
          </ThemeProvider>
        </ThemeProviderUI>
      </StyledEngineProvider>
    </Suspense>
  );
};
