import { authHeader, baseUrl, handleResponse } from "../../helpers";
import axios from "axios";

const loadDrawerMenu = async (token) => {
  let res = {
    result: true,
    status: 200,
  }; 
  
  return await axios
    .get(
      `${baseUrl()}/api/auth/navigation/COUPON`,     
      {
        headers:  authHeader(token),
      }
    )
    .then((response) => {
      return { ...res, data: response.data, status: response.status };
    })
    .catch(handleResponse);
};

export const menuService = {
    loadDrawerMenu,
};
