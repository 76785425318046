import React from "react";
import styled from "styled-components";
import Backdrop from "@mui/material/Backdrop";

const BackdropColor = styled(Backdrop)`
  background-color: ${(props) =>
    props.backdrop === true ? props.theme.body.bg : props.theme.loading.bg};
`;

const Image = styled.img`
  width: 13vh;
  max-width: 100px;
`;

const Loading = ({ backdrop }) => {  
  return (
    <BackdropColor backdrop={backdrop} open={true} style={{ zIndex: 1000 }}>
      <Image src="/assets/images/delivery-bike.gif" alt="Loading"></Image>
    </BackdropColor>
  );
};

export default Loading;
