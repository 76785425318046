import {
    REFRESHTABLE_CUSTOMERINFORMATION,
    SETDATA_CUSTOMERINFORMATION,
    SETDATALOADING_CUSTOMERINFORMATION,
    REFRESHTABLE_CUSTOMERINFORMATIONCONTACT,
    SETDATA_CUSTOMERINFORMATIONCONTACT,
    SETCUSTOMERID_CUSTOMERINFORMATION,
    SETPOINTS_CUSTOMERINFORMATION,
    REFRESHTABLE_CUSTOMERINFORMATIONPOINTS
} from "../constants/customerinformationConstants";

const initialState = {
    customerid: null,
    refreshtable: false,
    data: [],
    dataloading: false,
    refreshtablecontact: false,
    datacontact: [],
    refreshtablepoints: false,
    points: {
        total_earn_points: 0,
        total_redeem_points: 0,
        total_expired_points: 0,
        total_balance_points: 0,
        earnpoints: [],
        redeempoints: [],
        expiredpoints: []
    },
};

const coupontype = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_CUSTOMERINFORMATION:
            let data = {}
            if (action.payload) {
                data = {
                    // dialogadd: {
                    //     open: false,
                    //     loading: false
                    // },
                    // dialogedit: {
                    //     open: false,
                    //     loading: false,
                    //     data: null
                    // },
                    // dialogdelete: {
                    //     open: false,
                    //     loading: false,
                    //     data: null
                    // }
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case REFRESHTABLE_CUSTOMERINFORMATIONCONTACT:
            return {
                ...state,
                refreshtablecontact: action.payload,
            };
        case SETDATA_CUSTOMERINFORMATION:
            return {
                ...state,
                data: action.payload,
            };
        case SETDATALOADING_CUSTOMERINFORMATION:
            return {
                ...state,
                dataloading: action.payload
            }
        case SETDATA_CUSTOMERINFORMATIONCONTACT:
            return {
                ...state,
                datacontact: action.payload,
            };
        case SETCUSTOMERID_CUSTOMERINFORMATION:
            return {
                ...state,
                customerid: action.payload,
            };
        case SETPOINTS_CUSTOMERINFORMATION:
            return {
                ...state,
                points: {
                    ...state.points,
                    ...action.payload
                }
            };
        case REFRESHTABLE_CUSTOMERINFORMATIONPOINTS:
            return {
                ...state,
                refreshtablepoints: action.payload,
            }
        default:
            return state;
    }
};

export default coupontype;