export const SEARCH_LOADING = "SEARCH_LOADING";
export const SET_OPSTIONS = "SET_OPSTIONS";
export const SEATCH_DATA = "SEATCH_DATA";
export const REFRESHTABLE = "REFRESHTABLE";
export const SETDATA = "SETDATA";
export const DIALOGCONFIRMECODE = "DIALOGCONFIRMECODE";
export const DIALOGCONFIRMECODE_LOADING = "DIALOGCONFIRMECODE_LOADING";
export const DIALOGCANCELCODE = "DIALOGCANCELCODE";
export const DIALOGCANCELCODE_LOADING = "DIALOGCANCELCODE_LOADING";
export const SETSTORE_DIALOG = "SETSTORE_DIALOG";
