import {
  LOGIN_REQUEST,
  LOGIN_REMEMBER,
} from "../../store/constants/authConstants";

import { authService } from "../../services/auth";
import { pageActions, menuActions } from "../common";
import { userActions } from "./";
import { notifierActions } from "../notifier";

import Button from "@mui/material/Button";

const login = (username, password, remember, from, translate, language) => async (
  dispatch
) => {
  const enqueueSnackbar = (...args) =>
    dispatch(notifierActions.enqueueSnackbar(...args));
  const closeSnackbar = (...args) =>
    dispatch(notifierActions.closeSnackbar(...args));

  dispatch(request(true));
  const result = await authService.login(username, password);  
  if (result.result) {
    const { data } = result;

    //Clear Time out
    dispatch(pageActions.timeOut(false));

    //Remember
    dispatch(rememember(username, password, remember));
   
    //setUser profile
    dispatch(userActions.setUser(data));
    
    // //setMenu
    // dispatch(menuActions.setMenu(result.data.userScheme.userSearch));
    
    //setToken
    dispatch(userActions.setToken(data));

    //setRedireact
    dispatch(pageActions.redirectPage(from.pathname));

    //Alert message
    enqueueSnackbar({      
      message: language == "en" ? data.status.description_en : data.status.description_th,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
        style: { whiteSpace: 'pre-line' },
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {translate("dismiss.label")}
          </Button>
        ),
      },
    });
  } else {    
    const { data } = result;

    if (result.status === 401) {     
      enqueueSnackbar({
        message: language == "en" ? data.status.description_en : data.status.description_th,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
          style: { whiteSpace: 'pre-line' },
          action: (key) => (
            <Button
              style={{ color: "white" }}
              onClick={() => closeSnackbar(key)}
            >
              {translate("dismiss.label")}
            </Button>
          ),
        },
      });
      dispatch(request(false));
    } else {
      enqueueSnackbar({
        message: (data) ? language == "en" ? data.status.description_en : data.status.description_th : translate("server.error.label"),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
          style: { whiteSpace: 'pre-line' },
          action: (key) => (
            <Button
              style={{ color: "white" }}
              onClick={() => closeSnackbar(key)}
            >
              {translate("dismiss.label")}
            </Button>
          ),
        },
      });
      dispatch(request(false));
    }
  }
  dispatch(request(false));

  function request(value) {
    return { type: LOGIN_REQUEST, payload: value };
  }

  function rememember(username, password, remember) {
    const value = {
      loading: false,
      rememberme: remember,
      username: remember ? username : "",
      password: remember ? password : "",
    };
    return { type: LOGIN_REMEMBER, payload: value };
  }
};

const logout = (translate) => async (dispatch) => {
  const enqueueSnackbar = (...args) =>
    dispatch(notifierActions.enqueueSnackbar(...args));
  const closeSnackbar = (...args) =>
    dispatch(notifierActions.closeSnackbar(...args));
  
  dispatch(userActions.clearToken());

  dispatch(pageActions.redirectPage("/signin"));

  enqueueSnackbar({
    message: translate("logout.success.label"),
    options: {
      key: new Date().getTime() + Math.random(),
      variant: "success",
      style: { whiteSpace: 'pre-line' },
      action: (key) => (
        <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
          {translate("dismiss.label")}
        </Button>
      ),
    },
  });
};

export const authActions = {
  login,
  logout,
  // register,
  // getAll,
  // delete: _delete
};

// function logout() {
//     userService.logout();
//     return { type: userConstants.LOGOUT };
// }

// function register(user) {
//     return dispatch => {
//         dispatch(request(user));

//         userService.register(user)
//             .then(
//                 user => {
//                     dispatch(success());
//                     history.push('/login');
//                     dispatch(alertActions.success('Registration successful'));
//                 },
//                 error => {
//                     dispatch(failure(error.toString()));
//                     dispatch(alertActions.error(error.toString()));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
//     function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
// }

// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         userService.getAll()
//             .then(
//                 users => dispatch(success(users)),
//                 error => dispatch(failure(error.toString()))
//             );
//     };

//     function request() { return { type: userConstants.GETALL_REQUEST } }
//     function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {
//         dispatch(request(id));

//         userService.delete(id)
//             .then(
//                 user => dispatch(success(id)),
//                 error => dispatch(failure(id, error.toString()))
//             );
//     };

//     function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
//     function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
//     function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
// }
