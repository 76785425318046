import { SET_ROLES } from "../constants/rolesConstants";
import _ from "lodash";

const initialState = {
    page: []
};

const roles = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLES:           
            let roles = state.page;          
            //check value path
            if (_.find(state.page, { path: action.payload.path })) {    
                //clear roles           
                _.remove(roles, item => item.path === action.payload.path); 
                //add new data             
                roles = [...state.page, action.payload];             
            } else {
                //add roles
                roles = [...state.page, action.payload];               
            }       
            return {
                ...state,
                page: roles              
            };
        default:
            return state;
    }
};

export default roles;
