import { SET_ROLES } from "../../store/constants/rolesConstants";

const setRoles = (data) => async (dispatch) => {
  dispatch(setRoles(data));
  function setRoles(value) {
    return { type: SET_ROLES, payload: value };
  }
};


export const rolesActions = {
  setRoles
};