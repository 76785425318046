import { lazy } from "react";
const CustomerInformation = lazy(() => import("./components/CustomerInformation"));
const ValidateCode = lazy(() => import("./components/ValidateCode"));
const CouponType = lazy(() => import("./components/CouponType"));
const Blacklist = lazy(() => import("./components/Blacklist"));

export const routes = [   
    {
        path: "/customer/information",
        exact: true,
        component: CustomerInformation,
        name: "customerinformation"
    },
    {
        path: "/validatecode",
        exact: true,
        component: ValidateCode,
        name: "validatecode"
    },    
    {
        path: "/coupontype",
        exact: true,
        component: CouponType,
        name: "coupontype"
    },   
    {
        path: "/blacklist",
        exact: true,
        component: Blacklist,
        name: "blacklist"
    } 
];