import {
  SET_USERS,
  SET_TOKEN,
  CLEAR_TOKEN,
} from "../../store/constants/userConstants";

const setUser = (value) => async (dispatch) => {  
  const profile = {
    user: value.data.username,
    role_name_en: value.data.role_name_en,
    role_name_th: value.data.role_name_th
  };
  dispatch(setUser(profile));

  function setUser(value) {
    return { type: SET_USERS, payload: value };
  }
};

const setToken = (value) => async (dispatch) => {
  dispatch(setToken(value.data.token));
  function setToken(value) {
    return { type: SET_TOKEN, payload: value };
  }
};

const clearToken = () => async (dispatch) => {
  dispatch(clearToken());
  function clearToken() {
    return { type: CLEAR_TOKEN };
  }
};

export const userActions = {
  setUser,
  setToken,
  clearToken,
};
