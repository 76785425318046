import { lazy } from "react";
const Home = lazy(() => import("./components/Home"));

export const routes = [   
    {
        path: "/",
        exact: true,
        component: Home,
        name: "home"
    }    
];