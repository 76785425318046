// import article from './reducers/article';
// import articleList from './reducers/articleList';
import authReducer from './reducers/auth.reducer';
import userReducer from './reducers/user.reducer';
import roleReducer from './reducers/role.reducer';
import commonReducer from './reducers/common.reducer';
import notifierReducer from './reducers/notifier.reduces';
import statusReducer from './reducers/status.reducer';
import customerinformationReducer from './reducers/customerinformation.reducer';
import validatecodeReducer from './reducers/validatecode.reducer';
import coupontypeReducer from './reducers/coupontype.reducer';
import generatecouponcodeReducer from './reducers/generatecouponcode.reducer';
import importcouponcodeReducer from './reducers/importcouponcode.reducer';
import blacklistReducer from './reducers/blacklist.reducer';
import userprofileReducer from './reducers/userprofile.reducer';
import storeprofileReducer from './reducers/storeprofile.reducer';
import menufromeiosReducer from './reducers/menufromeios.reducer';
import { combineReducers } from 'redux';
// import common from './reducers/common';
// import editor from './reducers/editor';
// import home from './reducers/home';
// import profile from './reducers/profile';
// import settings from './reducers/settings';
// import { routerReducer } from 'react-router-redux';

export default combineReducers({
//   article,
//   articleList,
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  common: commonReducer,
  notifier: notifierReducer,
  status: statusReducer,
  customerinformation: customerinformationReducer,
  validatecode: validatecodeReducer,
  coupontype: coupontypeReducer,
  generatecouponcode: generatecouponcodeReducer,
  importcouponcode: importcouponcodeReducer,
  blacklist: blacklistReducer,
  userprofile: userprofileReducer,
  storeprofile: storeprofileReducer,
  menufromeios: menufromeiosReducer,
//   common,
//   editor,
//   home,
//   profile,
//   settings,
//   router: routerReducer
});