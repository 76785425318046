import { LOADING, SET_MENU, OPEN_MENU } from "../../store/constants/comonConstants";
import { menuService } from "../../services/common";
import { notifierActions } from "../notifier";

const getDrawerMenu = () => async (dispatch, getState) => {
  const { user } = getState();  
  const { token } = user;

  const enqueueSnackbar = (...args) =>
    dispatch(notifierActions.enqueueSnackbar(...args));
  const closeSnackbar = (...args) =>
    dispatch(notifierActions.closeSnackbar(...args));

  dispatch(loading(true));

  const result = await menuService.loadDrawerMenu(token);  
  if (result.result) {  
    dispatch(setMenu(result.data));
  } else {
    if (result.status === 401) {
      // //setRedireact
      // dispatch(pageActions.redirectPage("/signin"));

      // enqueueSnackbar({
      //   message:   ("alert.pleasesignin.label"),
      //   options: {
      //     key: new Date().getTime() + Math.random(),
      //     variant: "info",
      //     action: (key) => (
      //       <Button
      //         style={{ color: "white" }}
      //         onClick={() => closeSnackbar(key)}
      //       >
      //         {translate("dismiss.label")}
      //       </Button>
      //     ),
      //   },
      // });
    } else {
      // //setRedireact
      // dispatch(pageActions.redirectPage("/signin"));

      // enqueueSnackbar({
      //   message: translate("alert.server.error.label"),
      //   options: {
      //     key: new Date().getTime() + Math.random(),
      //     variant: "error",
      //     action: (key) => (
      //       <Button
      //         style={{ color: "white" }}
      //         onClick={() => closeSnackbar(key)}
      //       >
      //         {translate("dismiss.label")}
      //       </Button>
      //     ),
      //   },
      // });
    }
  }

  dispatch(loading(false));

  function loading(value) {
    return { type: LOADING, payload: value };
  }
};

const setMenu = (menu) => async (dispatch) => {
  dispatch(setmenu(menu.data.navbar));
  function setmenu(menu) {
    return { type: SET_MENU, payload: menu };
  }
};

const openMenu = (open) => async (dispatch) => {
  dispatch(setOpenMenu(open));
  function setOpenMenu(open) {
    return { type: OPEN_MENU, payload: open };
  }
};
export const menuActions = {
  getDrawerMenu,
  setMenu,
  openMenu,
};
