import { format, parse, getTime } from "date-fns";

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export function convertToDatestamp(date) {
  let Result = null;
  try {
    Result = getTime(date)
  } catch (err) { };
  return Result;
};

export function convertToStringFormat(date) {
  let Date = null;
  try {
    Date = format(date, 'yyyy-MM-dd HH:mm:sss')
  } catch (err) { };
  return Date;
};

export function parseStringToDate(date) {
  let Result = null;
  try {   
    Result = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());   
  } catch (err) { console.log(err)};
  return Result;
}

export function  datestampToExportFileFormat(stamp) {
  let Date = null;
  try {
      Date = format(stamp, 'yyyyMMdd_HH:mm:ss.SSS')
  } catch (err) { };
  return Date;
};