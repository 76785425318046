import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from "../../store/constants/notifierConstants";

const enqueueSnackbar = (notification) => async (dispatch) => {
  const key = notification.options && notification.options.key;

  dispatch(enqueue(key));

  function enqueue(key) {
    return {
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    };
  }
};

const closeSnackbar = (key) => async (dispatch) => {
  dispatch(close(key));

  function close(key) {
    return {
      type: CLOSE_SNACKBAR,
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    };
  }
};

const removeSnackbar = (key) => async (dispatch) => {
  dispatch(remove(key));

  function remove(key) {
    return {
      type: REMOVE_SNACKBAR,
      key,
    };
  }
};

export const notifierActions = {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar
};
