import { LOADING, REDIRECT, SET_TIMEOUT } from "../../store/constants/comonConstants";
import { pageService } from "../../services/common";
import { userActions } from "../auth";
import { notifierActions } from "../notifier";
import { rolesActions } from "../auth";

import Button from "@mui/material/Button";

const redirectPage = (path) => async (dispatch) => {
  dispatch(redirect(path));
  function redirect(value) {
    return { type: REDIRECT, payload: { status: true, path: value } };
  }
};

const clearRedireactPage = () => async (dispatch) => {
  dispatch(clearpage());
  function clearpage() {
    return { type: REDIRECT, payload: { status: false, path: "" } };
  }
};

const loadPage = (path, translate, language) => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const enqueueSnackbar = (...args) =>
    dispatch(notifierActions.enqueueSnackbar(...args));
  const closeSnackbar = (...args) =>
    dispatch(notifierActions.closeSnackbar(...args));

  dispatch(loading(true));

  const result = await pageService.loadPage(path, token);
  if (result.result) {
    const { data } = result;
    const roles = {
      ...data.data,
      path: path
    }
    dispatch(rolesActions.setRoles(roles));
  } else {
    const { data } = result;

    if (result.status === 401) {
      //setRedireact
      dispatch(pageActions.redirectPage("/signin"));

      enqueueSnackbar({
        message: language == "en" ? data.status.description_en : data.status.description_th,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "info",
          style: { whiteSpace: 'pre-line' },
          action: (key) => (
            <Button
              style={{ color: "white" }}
              onClick={() => closeSnackbar(key)}
            >
              {translate("dismiss.label")}
            </Button>
          ),
        },
      });
    } else {
      //setRedireact
      dispatch(pageActions.redirectPage("/signin"));

      enqueueSnackbar({
        message: (data) ? language == "en" ? data.status.description_en : data.status.description_th : translate("server.error.label"),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
          style: { whiteSpace: 'pre-line' },
          action: (key) => (
            <Button
              style={{ color: "white" }}
              onClick={() => closeSnackbar(key)}
            >
              {translate("dismiss.label")}
            </Button>
          ),
        },
      });
    }
  }

  dispatch(loading(false));

  function loading(value) {
    return { type: LOADING, payload: value };
  }
};

const timeOut = (value) => async (dispatch) => {
  dispatch(redirect(value));
  function redirect(value) {
    return { type: SET_TIMEOUT, payload: value };
  }
};

const refreshToken = () => async (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  const result = await pageService.refreshToken(token);  
  if (result.result) {
    const { data } = result;
    //setToken
    dispatch(userActions.setToken(data));
  };
};

export const pageActions = {
  redirectPage,
  clearRedireactPage,
  loadPage,
  timeOut,
  refreshToken
};
